@mixin desktop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin portrait {
  @media screen and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@font-face {
  font-family: "Basis";
  src: 
    local("Basis"),
    url("../fonts/Basis-Regular-Test.woff") format("woff"),
    url("../fonts/Basis-Regular-Test.woff2") format("woff2");
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
}

body {
  font-family: "Basis", sans-serif;
  font-size: 3.2vh;
  line-height: 3.6vh;
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 5vw;
  height: 5vw;
  z-index: 5;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: hidden;
  
  &-background {
    opacity: 0;
    
    .video-ready & {
      transition: opacity 0.5s 0.7s;
      opacity: 1;
    }

    .video-ready.no-fade & {
      transition: none;
    }
    
    @include portrait {
      opacity: 1;
    }
  }

  .loader {
    display: block;
    z-index: 1;
    width: 300px;
    height: 50px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate3d(-50%,-50%,0);
    opacity: 0;

    .is-loaded & {
      opacity: 1;
      transition: opacity 0s 0.5s;
    }

    .video-ready & {
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none;
    }

    @include portrait {
      display: none;
    }
  }
  
  .container {
    flex: 1;
    margin: 4vh;
    padding: 3.1vh 0 2.1vh;
    display: flex;
    justify-content: space-around;
    opacity: 0;
    
    .video-ready & {
      opacity: 1;
      transition: opacity 0s 1.2s;
    }

    .video-ready.no-fade & {
      transition: none;
    }
    
    @include portrait {
      opacity: 1;
      margin-left: 20px;
      margin-right: 20px;
    }
    
    a {
      text-align: center;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      line-height: 1;
      font-size: 3.7vw;
      opacity: 0;
      cursor: pointer;
      
      @include portrait {        
        span {
          display: block;
          line-height: 1.1;
          font-size: 3vh;
        }
      }
    }
  }
}

.menu-background {
  position: absolute;
  pointer-events: none;
  z-index: 2;
}

.menu-link-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  @extend %clickable;
  
  &.is-active {
    
  }
  
  &:nth-child(even) {
    flex-direction: column-reverse;
  }
  
  a:first-child {
    opacity: 1;
  }
}

.start-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  display: none;
}

.pages {
  padding: calc(4vh - 4px);
  padding-right: calc(4vh - 2px);
  display: flex;
  align-items: flex-start;
  visibility: hidden;
  transform: translateY(100vh);
  
  @include portrait {
    display: block;
    padding-top: 2vh;
  }
}

.page {
  display: none;
  flex: 1;
  
  &.is-open {
    display: flex;
  }
}

.page-logo {
  width: calc(20% - 1px);
  cursor: pointer;
  
  @include portrait {
    width: auto;
    padding-bottom: 20px;
    display: none;
    
    p {
      &:before { content: ", "; }
    }
  }
}

.page-body {
  flex: 1;
  min-width: 0;
}

.page-entry {
  display: flex;
  margin-bottom: 25px;
  
  @include portrait {
    display: block;
    margin-bottom: 40px;
  }
  
  &:last-child {
    margin-bottom: 25px;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    
    img {
      vertical-align: bottom;
    }
  }
}

.page-entry-aside {
  width: calc(25% + 1px);
  padding-right: 20px;
  
  @include portrait {
    width: auto;
    padding-right: 0;
    padding-bottom: 8px;
  }
}

.page-entry-content {
  flex: 1;
  
  iframe,
  img {
    width: 100%;
    height: auto;
  }

  ul {
    list-style: none;
    
    li {
      margin-bottom: 10px;
    }
  }

  a {
    text-decoration: underline;
  }
}

.youtube-player {
  overflow: hidden;
  background: black;
  
  &:hover .youtube-player-play {
    background-color: hsla(0,0%,0%,.5);
  }
  
  &,
  &-poster {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
  }

  &-poster {
    object-fit: cover;
    @extend %clickable;
  }

  &-play {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
    pointer-events: none;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    background: hsla(0,0%,0%,.1) url(../img/play.svg) center no-repeat;
    transition: background-color 240ms;
  }
}

[data-async] {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &.is-loading .loader {
    display: block;
  }

  .loader {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.loader {
  flex: 1;
  border-right: 4px solid black;
  border-left: 4px solid black;
  overflow: hidden;
  display: none;
  
  @include portrait {
    border-right-width: 2px;
    border-left-width: 2px;
  }
  
  &-inner {
    height: 100%;
    display: flex;
    animation: scroll linear 2s infinite;
    margin-left: -4px;
    
    @include portrait {
      margin-left: -2px;
    }

    @for $i from 0 through 20 {
      &[data-offset="#{$i}"] {
        animation-delay: 1.25s/$i;
      }
    }
  }
  
  &-box {
    height: 100%;
    width: 25%;
    border-left: 4px solid black;
    border-bottom: 4px solid black;
    flex-shrink: 0;
  
    @include portrait {
      border-left-width: 2px;
      border-bottom-width: 2px;
    }
    
    &:nth-child(even){
      border-bottom: none;
      border-top: 4px solid black;
  
      @include portrait {
        border-top-width: 2px;
      }
    }
  }
}

@keyframes scroll {
  to { transform: translateX(-100%); }
}

@keyframes background-scroll {
  from { background-position: 42px 0; }
  to { background-position: 0 0; }
}

@keyframes scale {
  from { background-position: 42px 0; transform: scale(1); }
  50% { background-position: 21px 0; transform: scale(1,0.8); }
  to { background-position: 0 0; transform: scale(1); }
}

#music {
  .soundcloud-player {
    min-height: 150px;

    iframe {
      display: block;
      width: 100%;
      height: auto;

      + div {
        display: none;
      }
    }
  }
}

#dates {
  .page-entry {
    margin-bottom: 200px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  .dates-show-more {
    margin: 100px 0;
    display: table;
  }
  
  @include portrait {
    .page-entry-aside {
      padding-bottom: 0.35em;
      border-bottom: 1px solid;
      margin-bottom: 0.55em;
    }
  }
}

#about {
  .page-entry:nth-child(n + 3) { // match discography and remixes
    @include portrait {
      /* cross browser inline-block */
      br {
        display: -moz-inline-stack;
        display: inline;
        vertical-align: top;
        zoom: 1;
        *display: inline;
      }

      /* replace <br> with comma */

      br {
        content: '';
      }

      br:before {
        content: ', '
      }
    }
  }
}

.embed-ratio-holder {
  padding: 28.125% 0;
  position: relative;
  
  iframe,
  [data-async] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  #video & {
    background: black;
  } 
}

.gig {
  display: flex;
  border-bottom: 1px solid;
  margin-bottom: 0.35em;
  padding-bottom: 0.25em;
  
  @include portrait {
    display: block;
    margin-bottom: 0.55em;
    padding-bottom: 0.35em;
  }
}

.gig-date {
  width: 33%;
  
  @include portrait {
    width: auto;
    margin-bottom: 0.2em;
  }
}

.gig-details {
  flex: 1;
}

[data-page="home"] {
  height: 200%;
  
  @include portrait {
    height: auto;
  }
  
  .menu {
    position: fixed;
    
    @include portrait {
      position: static;
    }
  }

  .pages {
    display: none;
  }

  .start-overlay {
    display: block;
    
    @include portrait {
      display: none;
    }
  }
}

.videos {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  
  video {
    opacity: 0;
    display: none;
    
    &:first-child {
      // clip-path: polygon(4% 100%, 4% 4%, 22.4% 4%, 22.4% 96%, 40.8% 96%, 40.8% 4%, 59.2% 4%, 59.2% 96%, 77.6% 96%, 77.6% 4%, 96% 4%, 96% 100%)
    }

    .video-playing & {
      display: block;
    }
  }
  
  @include portrait {
    display: none;
  }
}

.is-hidden {
  display: none;
}

.ratio-box {
  display: block;
  height: 0;
  position: relative;
  width: 100%;
  
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}