@font-face {
  font-family: "Basis";
  src: local("Basis"), url("../fonts/Basis-Regular-Test.woff") format("woff"), url("../fonts/Basis-Regular-Test.woff2") format("woff2"); }

.menu-link-box, .youtube-player-poster {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  overflow-y: scroll; }

body {
  font-family: "Basis", sans-serif;
  font-size: 3.2vh;
  line-height: 3.6vh; }

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 5vw;
  height: 5vw;
  z-index: 5; }

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: hidden; }
  .menu-background {
    opacity: 0; }
    .video-ready .menu-background {
      transition: opacity 0.5s 0.7s;
      opacity: 1; }
    .video-ready.no-fade .menu-background {
      transition: none; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .menu-background {
        opacity: 1; } }
  .menu .loader {
    display: block;
    z-index: 1;
    width: 300px;
    height: 50px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0; }
    .is-loaded .menu .loader {
      opacity: 1;
      transition: opacity 0s 0.5s; }
    .video-ready .menu .loader {
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .menu .loader {
        display: none; } }
  .menu .container {
    flex: 1;
    margin: 4vh;
    padding: 3.1vh 0 2.1vh;
    display: flex;
    justify-content: space-around;
    opacity: 0; }
    .video-ready .menu .container {
      opacity: 1;
      transition: opacity 0s 1.2s; }
    .video-ready.no-fade .menu .container {
      transition: none; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .menu .container {
        opacity: 1;
        margin-left: 20px;
        margin-right: 20px; } }
    .menu .container a {
      text-align: center;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      line-height: 1;
      font-size: 3.7vw;
      opacity: 0;
      cursor: pointer; }
      @media screen and (max-aspect-ratio: 1 / 1) {
        .menu .container a span {
          display: block;
          line-height: 1.1;
          font-size: 3vh; } }

.menu-background {
  position: absolute;
  pointer-events: none;
  z-index: 2; }

.menu-link-box {
  flex: 1;
  display: flex;
  flex-direction: column; }
  .menu-link-box:nth-child(even) {
    flex-direction: column-reverse; }
  .menu-link-box a:first-child {
    opacity: 1; }

.start-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  display: none; }

.pages {
  padding: calc(4vh - 4px);
  padding-right: calc(4vh - 2px);
  display: flex;
  align-items: flex-start;
  visibility: hidden;
  transform: translateY(100vh); }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .pages {
      display: block;
      padding-top: 2vh; } }

.page {
  display: none;
  flex: 1; }
  .page.is-open {
    display: flex; }

.page-logo {
  width: calc(20% - 1px);
  cursor: pointer; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .page-logo {
      width: auto;
      padding-bottom: 20px;
      display: none; }
      .page-logo p:before {
        content: ", "; } }

.page-body {
  flex: 1;
  min-width: 0; }

.page-entry {
  display: flex;
  margin-bottom: 25px; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .page-entry {
      display: block;
      margin-bottom: 40px; } }
  .page-entry:last-child {
    margin-bottom: 25px; }
  .page-entry a {
    color: inherit;
    text-decoration: none; }
    .page-entry a img {
      vertical-align: bottom; }

.page-entry-aside {
  width: calc(25% + 1px);
  padding-right: 20px; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .page-entry-aside {
      width: auto;
      padding-right: 0;
      padding-bottom: 8px; } }

.page-entry-content {
  flex: 1; }
  .page-entry-content iframe,
  .page-entry-content img {
    width: 100%;
    height: auto; }
  .page-entry-content ul {
    list-style: none; }
    .page-entry-content ul li {
      margin-bottom: 10px; }
  .page-entry-content a {
    text-decoration: underline; }

.youtube-player {
  overflow: hidden;
  background: black; }
  .youtube-player:hover .youtube-player-play {
    background-color: rgba(0, 0, 0, 0.5); }
  .youtube-player, .youtube-player-poster {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important; }
  .youtube-player-poster {
    object-fit: cover; }
  .youtube-player-play {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
    pointer-events: none;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.1) url(../img/play.svg) center no-repeat;
    transition: background-color 240ms; }

[data-async] {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  [data-async].is-loading .loader {
    display: block; }
  [data-async] .loader {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.loader {
  flex: 1;
  border-right: 4px solid black;
  border-left: 4px solid black;
  overflow: hidden;
  display: none; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .loader {
      border-right-width: 2px;
      border-left-width: 2px; } }
  .loader-inner {
    height: 100%;
    display: flex;
    animation: scroll linear 2s infinite;
    margin-left: -4px; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .loader-inner {
        margin-left: -2px; } }
    .loader-inner[data-offset="0"] {
      animation-delay: Infinity; }
    .loader-inner[data-offset="1"] {
      animation-delay: 1.25s; }
    .loader-inner[data-offset="2"] {
      animation-delay: 0.625s; }
    .loader-inner[data-offset="3"] {
      animation-delay: 0.41667s; }
    .loader-inner[data-offset="4"] {
      animation-delay: 0.3125s; }
    .loader-inner[data-offset="5"] {
      animation-delay: 0.25s; }
    .loader-inner[data-offset="6"] {
      animation-delay: 0.20833s; }
    .loader-inner[data-offset="7"] {
      animation-delay: 0.17857s; }
    .loader-inner[data-offset="8"] {
      animation-delay: 0.15625s; }
    .loader-inner[data-offset="9"] {
      animation-delay: 0.13889s; }
    .loader-inner[data-offset="10"] {
      animation-delay: 0.125s; }
    .loader-inner[data-offset="11"] {
      animation-delay: 0.11364s; }
    .loader-inner[data-offset="12"] {
      animation-delay: 0.10417s; }
    .loader-inner[data-offset="13"] {
      animation-delay: 0.09615s; }
    .loader-inner[data-offset="14"] {
      animation-delay: 0.08929s; }
    .loader-inner[data-offset="15"] {
      animation-delay: 0.08333s; }
    .loader-inner[data-offset="16"] {
      animation-delay: 0.07812s; }
    .loader-inner[data-offset="17"] {
      animation-delay: 0.07353s; }
    .loader-inner[data-offset="18"] {
      animation-delay: 0.06944s; }
    .loader-inner[data-offset="19"] {
      animation-delay: 0.06579s; }
    .loader-inner[data-offset="20"] {
      animation-delay: 0.0625s; }
  .loader-box {
    height: 100%;
    width: 25%;
    border-left: 4px solid black;
    border-bottom: 4px solid black;
    flex-shrink: 0; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .loader-box {
        border-left-width: 2px;
        border-bottom-width: 2px; } }
    .loader-box:nth-child(even) {
      border-bottom: none;
      border-top: 4px solid black; }
      @media screen and (max-aspect-ratio: 1 / 1) {
        .loader-box:nth-child(even) {
          border-top-width: 2px; } }

@keyframes scroll {
  to {
    transform: translateX(-100%); } }

@keyframes background-scroll {
  from {
    background-position: 42px 0; }
  to {
    background-position: 0 0; } }

@keyframes scale {
  from {
    background-position: 42px 0;
    transform: scale(1); }
  50% {
    background-position: 21px 0;
    transform: scale(1, 0.8); }
  to {
    background-position: 0 0;
    transform: scale(1); } }

#music .soundcloud-player {
  min-height: 150px; }
  #music .soundcloud-player iframe {
    display: block;
    width: 100%;
    height: auto; }
    #music .soundcloud-player iframe + div {
      display: none; }

#dates .page-entry {
  margin-bottom: 200px; }
  #dates .page-entry:last-child {
    margin-bottom: 0; }

#dates .dates-show-more {
  margin: 100px 0;
  display: table; }

@media screen and (max-aspect-ratio: 1 / 1) {
  #dates .page-entry-aside {
    padding-bottom: 0.35em;
    border-bottom: 1px solid;
    margin-bottom: 0.55em; } }

@media screen and (max-aspect-ratio: 1 / 1) {
  #about .page-entry:nth-child(n + 3) {
    /* cross browser inline-block */
    /* replace <br> with comma */ }
    #about .page-entry:nth-child(n + 3) br {
      display: -moz-inline-stack;
      display: inline;
      vertical-align: top;
      zoom: 1;
      *display: inline; }
    #about .page-entry:nth-child(n + 3) br {
      content: ''; }
    #about .page-entry:nth-child(n + 3) br:before {
      content: ', '; } }

.embed-ratio-holder {
  padding: 28.125% 0;
  position: relative; }
  .embed-ratio-holder iframe,
  .embed-ratio-holder [data-async] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  #video .embed-ratio-holder {
    background: black; }

.gig {
  display: flex;
  border-bottom: 1px solid;
  margin-bottom: 0.35em;
  padding-bottom: 0.25em; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .gig {
      display: block;
      margin-bottom: 0.55em;
      padding-bottom: 0.35em; } }

.gig-date {
  width: 33%; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .gig-date {
      width: auto;
      margin-bottom: 0.2em; } }

.gig-details {
  flex: 1; }

[data-page="home"] {
  height: 200%; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    [data-page="home"] {
      height: auto; } }
  [data-page="home"] .menu {
    position: fixed; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      [data-page="home"] .menu {
        position: static; } }
  [data-page="home"] .pages {
    display: none; }
  [data-page="home"] .start-overlay {
    display: block; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      [data-page="home"] .start-overlay {
        display: none; } }

.videos {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden; }
  .videos video {
    opacity: 0;
    display: none; }
    .video-playing .videos video {
      display: block; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .videos {
      display: none; } }

.is-hidden {
  display: none; }

.ratio-box {
  display: block;
  height: 0;
  position: relative;
  width: 100%; }
  .ratio-box img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block; }
